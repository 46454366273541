import NavbarComponent from './components/Navbarcomponent';
import GameComponent from './components/Gamecomponent';
import ParralaxComponent from './components/Parallaxcomponent';
import LanguageComponent from './components/Languagecomponent';
import DamierComponent from './components/DamierComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Checkerboard1 from './components/Checkerboard1';
import Reseau from './components/ReseauComponent';
function App() {
  return (
    <>
   <ParralaxComponent text="REZKI" ></ParralaxComponent>
   <NavbarComponent></NavbarComponent>
   <DamierComponent></DamierComponent>
   <Checkerboard1></Checkerboard1>
   <LanguageComponent></LanguageComponent>
   <Reseau></Reseau>
   <GameComponent></GameComponent>
   <footer class="bg-primary text-light text-center py-3">
    <p >&copy; 2024 Bessahli Rezki. Tous droits réservés.</p>
  </footer>
   </>
  );
}


export default App;
